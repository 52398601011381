import React, { useState } from 'react'
import Login from '../authentication/login/login'
import Signup from '../authentication/signup/signup'
import Footer from '../shared/footer/footer'
import Header from '../shared/header/header'

const HowItWorks = () => {
    const [state, setState] = useState({
        popupVisible: false,
        isLoginModalVisible: false,
        isSignupModalVisible:false,
        text: null,
      })
      const handleLoginModal = (bool) => { setState({...state, isLoginModalVisible : bool }) }

  const handleSignupModal = (bool) => { setState({...state, isSignupModalVisible: bool }) }
  return (
    <>
    <Header handleLoginModal={handleLoginModal} handleSignupModal={handleSignupModal} />
    <Login isModalVisible={state.isLoginModalVisible} handleModal={handleLoginModal} />
        <Signup isModalVisible={state.isSignupModalVisible} handleModal={handleSignupModal} />

        <section className="home-banner work-banner">

    <img src="../images/howitwork-banner.jpg" alt=""/>


    <div className="tag">
      <h1>How it works?</h1>
      <h2>Grow your fanbase</h2>

    </div>

  </section>



  <section className="aboutus background1">

    <div className="container">


      <div className="feature_boxes row_parent">

        <div className="row flex1 mb-10">

          <div className="col_left">
            <div className="heads">

              <h3>Step one</h3>
              <h4>Your best promotional content</h4>
              <span className="line"></span>
            </div>

            <p>Choose a video of you playing music. The first few seconds need to be as engaging as possible to win the
              attention of your future fans. Make sure to edit or “trim” so the video begins at an interesting and
              attention grabbing moment.</p>

            <a href="#" className="btn mt-4">TIPS - CHOOSING YOUR VIDEO</a>

          </div>

          <div className="col_right">
            <div className="img">
              <img src="../images/step1.jpg" alt=""/>
            </div>


          </div>

        </div>


        <div className="row flex1 mb-10">

          <div className="col_left">

            <div className="heads">

              <h3>Step two</h3>
              <h4>Soundalikes</h4>
              <span className="line"></span>
            </div>

            <p>What bands and artists do you and your fans think you sound the most like?</p>


          </div>

          <div className="col_right">

            <div className="img">
              <img src="../images/step2.jpg" alt=""/>
            </div>


          </div>

        </div>

        <div className="row flex1 mb-10">

          <div className="col_left">

            <div className="heads">

              <h3>Step three</h3>
              <h4>Genre</h4>
              <span className="line"></span>
            </div>
            <p>You are an artist and not a marketer. Spend your
              time doing what you love and we'll help you
              achieve your music career goals.</p>

            <p>Nothing is more satisfying than growing your
              fanbase. When your talent reaches everywhere
              in the world. </p>

          </div>

          <div className="col_right ">

            <div className="img">
              <img src="../images/step3.jpg" alt=""/>
            </div>


          </div>

        </div>


        <div className="row flex1 mb-10">

          <div className="col_left">
            <div className="heads">

              <h3>Step four</h3>
              <h4>Taglines</h4>
              <span className="line"></span>
            </div>

            <p>Sometimes we need a short piece of text to accompany your content, these Taglines are pre-tested text
              headlines that ensure the greatest chance to capture a future fan’s attention whenever they are shown.</p>

          </div>

          <div className="col_right">

            <div className="img">
              <img src="../images/step4.jpg" alt=""/>
            </div>



          </div>

        </div>

        <div className="row flex1">

          <div className="col_left">

            <div className="heads">

              <h3>Step five</h3>
              <h4>Set Daily Budget</h4>
              <span className="line"></span>
            </div>
            <p>Fanify’s Daily Budget directly covers your music promotion costs across all digital marketing platforms,
              showing your content to an audience that love to discover new music and are ready to become fans for life.
            </p>

          </div>
          <div className="col_right ">

            <div className="img">
              <img src="../images/step3.jpg" alt=""/>
            </div>


          </div>

        </div>


        <div className="center pb-5 pt-5">
          <a href="#" className="btn">GET REAL FANS NOW</a>
        </div>


      </div>


    </div>
  </section>
  <Footer />
    </>
  )
}

export default HowItWorks